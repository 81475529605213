import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { Authorized, LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";
import { url } from "inspector";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main
      style={{
        backgroundColor: "",
        display: "flex",
        height: "100vh",
        
      }}
    >
      <div
        style={{
          padding: 30,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            backgroundColor: "",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {wallet && (
            <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
          )}
          <div></div>
          <div>
            <a href="https://discord.gg/5YSTaFeyuG" target="_blank"><img style={{width: "10%", padding: 10,}} src="./Discord.png" alt="discord logo"/></a>
          {/* </div><div> */}
            <a href="https://twitter.com/floorBirdz" target="_blank"><img style={{width: "10%", padding: 10,}} src="./dis.png" alt="twitter logo"/></a>
          </div>

          <ConnectButton>
            {wallet ? "Connected" : "Connect Wallet"}
          </ConnectButton>
        </div>
        <div
          style={{
            backgroundColor: "",
            flex: 1,
            width: "98%",
            display: "flex",
            
            margin: "5px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            
            
          }}
        >
          <img
            src="./New1birdzBanner.png"
            alt="sick opening banner for floorbirdz project"
          />
        </div>
        <div
          style={{
            backgroundColor: "",
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <MintContainer>
            <MintButton
              disabled={isSoldOut || isMinting || !isActive}
              onClick={onMint}
              variant="contained"
              style={{
                marginBottom: 10,
              }}
            >
              {isSoldOut ? (
                "SOLD OUT"
              ) : isActive ? (
                isMinting ? (
                  <CircularProgress />
                ) : (
                  "MINT"
                )
              ) : (
                <Countdown
                  date={startDate}
                  onMount={({ completed }) => completed && setIsActive(true)}
                  onComplete={() => setIsActive(true)}
                  renderer={renderCounter}
                />
              )}
            </MintButton>
          </MintContainer>
          <div>
            {wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>}

            {wallet && (
              <p>
                Supply: {itemsRemaining}/{itemsAvailable}
              </p>
            )}
          </div>
        </div>
        <div 
        style={{  
          backgroundImage: "url(" + "https://cdn.pixabay.com/photo/2015/03/26/10/24/birds-691274_960_720.jpg" + ")",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'repeat',
          padding: "20px",
          borderRadius: "30px",
                  }}>
        <div
          style={{
            
            fontSize: 26,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            flexDirection: "column",
            margin: "20px",
            borderRadius: "30px",
          }}
        >
          <h1>Presale: .22 SOL </h1><h1>Public: .33 SOL Supply: 3,333
          </h1>
          <h3>
            With all the new fashions in shit coins and deflationary floor
            burning going on in the SOL world, we noticed something…
          </h3>
          <h1>Someone needs to sweep the floor!</h1>
          <h3>That is where floorBirdz comes in.</h3>
        </div>
        <div
          style={{
            
            // backgroundColor: "darkGrey",
            fontSize: 26,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            flexDirection: "column",
            margin: "50px",
            borderRadius: "30px",
          }}
        >
          
          <h2>BirdzTrust - Fractionalized Ownership - Community DAO </h2>
          <h3>
            First Sweep: thugbirdz
          </h3>
          
        </div>
        <div
          style={{
            backgroundColor: "darkGrey",
            fontSize: 26,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            flexDirection: "column",
            margin: "40px",
            borderRadius: "30px",
          }}
        >
          <p  style={{margin: "5px 40px 5px 40px"}}>
          50% of all mint and royalty funds will be used to sweep the floors
            of our favorite blue-chip NFT’s - yes we are sweeping our favorite NFT's in the SOL-verse. Owning a floorBirdz NFT will
            also mean communal membership in the BirdzTrust, which will hold
            community funds and our future moon birdz!
          </p>
        </div>
        </div>


        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
